
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Action} from "vuex-class";
import {StoreAction} from "@/types";
import moment from "moment-timezone";
import Visualisations from "@/components/Visualisations.vue";
import Bar from "@/components/charts/bar.vue";
import Pie from "@/components/charts/pie.vue";
import InlineField from "@/components/InlineField.vue";
import {mixins} from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import Tooltip from '@/dsComponents/Tooltip/Tooltip.vue';

@Component({
    name: "Statistics",
    components: {InlineField, Pie, Bar, Visualisations, Tooltip}
})
export default class Statistics extends mixins(PageMixin) {
    pageTitle = this.$t('page.statistics.title');
    loading = false;
    timeout:any = 0;
    stats:any = null;

    visualisations: any = {
        dateRange: {
            from: moment().startOf('week').valueOf(),
            to: moment().endOf('week').valueOf(),
        },
        mode: 'any'
    }

    @Action('actions/getStats') getStats!: StoreAction;

    @Watch('visualisations', {deep: true})
    onVisualisations() {
        this.init();
    }

    get ranges() {
        let fromDate, toDate;

        if (typeof this.visualisations.dateRange.from === 'number') {
            fromDate = this.visualisations.dateRange.from;
            toDate = this.visualisations.dateRange.to;
        } else {
            fromDate = this.visualisations.dateRange.from.valueOf();
            toDate = this.visualisations.dateRange.to.valueOf();
        }

        return {
            from: fromDate,
            to: toDate
        }
    }

    get activity() {
        return this.stats?.activity && {
            datasets: [
                {
                    label: this.$t('page.statistics.activity.receivedCommands'),
                    backgroundColor: "#2766CD",
                    hoverBackgroundColor: "#2766CD",
                    data: [this.stats.activity.receivedCommands],
                },
                {
                    label: this.$t('page.statistics.activity.completedCommands'),
                    backgroundColor: "#4F8CCD",
                    hoverBackgroundColor: "#4F8CCD",
                    data: [this.stats.activity.completedCommands],
                },
                {
                    label: this.$t('page.statistics.activity.canceledCommands'),
                    backgroundColor: "#D45656",
                    hoverBackgroundColor: "#D45656",
                    data: [this.stats.activity.canceledCommands],
                },
                {
                    label: this.$t('page.statistics.activity.positionsToFilled'),
                    backgroundColor: "#38B4DD",
                    hoverBackgroundColor: "#38B4DD",
                    data: [this.stats.activity.positionsToFilled],
                },
                {
                    label: this.$t('page.statistics.activity.positionsFilled'),
                    backgroundColor: "#80D678",
                    hoverBackgroundColor: "#80D678",
                    data: [this.stats.activity.positionsFilled],
                },
            ]
        }
    }

    get attractiveness() {
        return this.stats?.attractiveness && {
            labels: [
                this.$t('page.statistics.attractiveness.workersAcceptedCount'),
                this.$t('page.statistics.attractiveness.workersDeclinedCount'),
                this.$t('page.statistics.attractiveness.workersNotRespondedCount'),
                this.$t('page.statistics.attractiveness.canceledPropositions'),
            ],
            datasets: [
                {
                    backgroundColor: ["#80D678", "#D5D8E2", "#F9C28C", "#D45656"],
                    hoverBackgroundColor: ["#80D678", "#D5D8E2", "#F9C28C", "#D45656"],
                    data: [
                        Number(this.stats.attractiveness.workersAcceptedPercent),
                        Number(this.stats.attractiveness.workersDeclinedPercent),
                        Number(this.stats.attractiveness.workersNotRespondedPercent),
                        Number(this.stats.attractiveness.workersAbortPercent)
                    ]
                }
            ]
        }
    }

    get optimization() {
        if (this.stats?.optimization) {
            let nw = this.stats.optimization.notConfirmedWorkers;
            let cw = this.stats.optimization.confirmedWorkers;
            return {
                labels: [
                    this.$t('page.statistics.optimization.workersWithoutMission'),
                    this.$t('page.statistics.optimization.workersWithMission'),
                ],
                datasets: [
                    {
                        backgroundColor: ["#F9C28C", "#80D678"],
                        hoverBackgroundColor: ["#F9C28C", "#80D678"],
                        data: [
                            nw === 0 ? 0 : cw === 0 ? 100 : Math.round(nw / (nw + cw) * 100),
                            cw === 0 ? 0 : nw === 0 ? 100 : Math.round(cw / (nw + cw) * 100),
                        ]
                    }
                ]
            };
        }
    }

    get delayActivity() {
        const d = moment.duration(this.stats.activity.timeToCompleteCommands);
        let h = d.asHours().toFixed(2).split('.')[0];
        let m = parseInt((d.asMinutes() - (h * 60)).toString());
        return h + "h" + (m.toString().length === 1 ? "0" + m : m) + "m";
    }

    get delayAttractiveness() {
        const d = moment.duration(this.stats.attractiveness.propositionTimeResponseMean);
        let h = d.asHours().toFixed(2).split('.')[0];
        let m = parseInt((d.asMinutes() - (h * 60)).toString());
        return h + "h" + (m.toString().length === 1 ? "0" + m : m) + "m";
    }

    timedInit() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init();
        }, 300);
    }

    async init() {
        this.loading = true;
        try {
            this.stats = await this.getStats(this.ranges);
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
